var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ion-page',[_c('a-navbar'),_c('ion-content',{staticClass:"ion-padding"},[_c('div',{staticClass:"w-vcenter"},[_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.enter)}}},[_c('h1',[_vm._v("Prihlásenie")]),_c('p',{staticClass:"ion-margin-bottom fs-normal"},[_vm._v("Zadaj svoj mail a heslo a registruj sa")]),_c('validation-provider',{attrs:{"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('ion-input',{staticClass:"ion-margin-top",attrs:{"placeholder":"Email","value":_vm.email},on:{"ionInput":function($event){validate($event) && (_vm.email = $event.target.value)}}}),(errors && errors.length)?_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('div',{staticClass:"d-flex p-relative"},[_c('ion-input',{staticClass:"ion-margin-top reset-password-input",attrs:{"placeholder":"Heslo","type":"password","value":_vm.password},on:{"ionInput":function($event){validate($event) && (_vm.password = $event.target.value)}}},[_c('p',{staticClass:"ion-no-margin ion-padding-horizontal pointer forgot-pass",class:{ 'a-disabled': !_vm.email.length },on:{"click":function($event){return _vm.forgotPassword()}}},[_vm._v("Zabudol som heslo")])])],1),(errors && errors.length)?_c('p',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)}),_c('ion-button',{staticClass:"ion-margin-top",attrs:{"expand":"block","type":"submit","color":"green"}},[_vm._v("Prihlásiť")]),_c('p',{staticClass:"ion-margin-top"},[_vm._v("Ešte nemáš účet?"),_c('router-link',{staticClass:"login-link",attrs:{"to":"/registracia"}},[_vm._v("Vytvor si ho.")])],1)],1)]}}])})],1)])]),(_vm.loading)?_c('z-loader'):_vm._e(),_c('ion-footer',{staticClass:"ion-padding ion-margin-bottom"},[_c('a-private-field')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }