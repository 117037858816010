<template lang="pug">
	ion-page
		a-navbar
		ion-content.ion-padding
			.w-vcenter
				div
					validation-observer(v-slot='{ handleSubmit }')
						form(@submit.prevent='handleSubmit(enter)')
							h1 Prihlásenie
							p.ion-margin-bottom.fs-normal Zadaj svoj mail a heslo a registruj sa
							validation-provider(rules='email|required' v-slot='{ errors, validate }')
								ion-input.ion-margin-top(
									placeholder='Email'
									:value='email'
									@ionInput='validate($event) && (email = $event.target.value)'
								)
								p.input-error(v-if="errors && errors.length") {{ errors[0] }}
							validation-provider(rules='required' v-slot='{ errors, validate }')
								div.d-flex.p-relative
									ion-input.ion-margin-top.reset-password-input(
										placeholder='Heslo'
										type='password'
										:value='password'
										@ionInput='validate($event) && (password = $event.target.value)'
									)
										p.ion-no-margin.ion-padding-horizontal.pointer.forgot-pass(
											:class="{ 'a-disabled': !email.length }"
											@click="forgotPassword()"
										) Zabudol som heslo
								p.input-error(v-if="errors && errors.length") {{ errors[0] }}
							//p alebo...
							//div
							//  ion-button.outline(expand='block')
							//    .input-icon
							//      img(src='../assets/images/google-icon.svg')
							//    | Prihlásiť cez Google
							ion-button.ion-margin-top(expand='block' type='submit' color="green") Prihlásiť
							p.ion-margin-top Ešte nemáš účet?
								router-link.login-link(to='/registracia') Vytvor si ho.
		z-loader(v-if="loading")
		ion-footer.ion-padding.ion-margin-bottom
			a-private-field
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate'
import { email, required } from 'vee-validate/dist/rules'

extend('email', {
	...email,
	message: 'Neplatný email.'
})
extend('required', {
	...required,
	message: 'Toto pole je povinné'
})

export default {
	name: 'Login',
	components: {
		ValidationProvider,
		ValidationObserver,
		'a-private-field': () => import('../../app@components/private-field/a-private-field.vue'),
		'a-navbar': () => import('@/plugins/app@components/navbar/a-navbar.vue'),
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue')

	},
	data() {
		return {
			loading: false,
			email: '',
			password: '',
		}
	},
	methods: {
		async enter() {
			try {
				this.loading = true
				await this.$store.dispatch('wAuth/login', {
					login: this.email,
					password: this.password,
				})
				await this.$router.push({ name: 'Profile' })
			} catch (err) {
				console.log('catched', err)
				await this.$wToast.error(err)
			} finally {
				this.loading = false
			}
		},
		async forgotPassword() {
			if (!this.email.length) return
			try {
				this.loading = true
				await this.$store.dispatch('wAuth/forgotPassword', { email: this.email, role: 'student' })
				await this.$wToast.success('Email na obnovu hesla bol odoslaný')
			} catch (err) {
				await this.$wToast.error(err)
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
<style lang="sass" scoped>
ion-toolbar
	border-bottom: 5px solid #f7f7f9

.p-relative
	position: relative

.forgot-pass
	right: 0
	position: absolute

.input-icon
	margin-right: 7px

.pointer
	cursor: pointer

.login-link
	cursor: pointer
	color: var(--a-black)
	font-weight: bold
	text-decoration: none
	margin-left: 4px

.input-error
	color: var(--a-red)
	margin-top: 0.25rem

.reset-password-input
	pointer-events: auto
</style>
